var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.exportDialog ? _c('export-dialog', {
    attrs: {
      "filename": "Benntlévők listája",
      "api-endpoint": "/occupants/export"
    },
    on: {
      "close": function ($event) {
        _vm.exportDialog = false;
      }
    }
  }) : _vm._e(), _vm.exitDialog ? _c('exit-dialog', {
    on: {
      "success": _vm.fetchItems
    },
    model: {
      value: _vm.exitDialog,
      callback: function ($$v) {
        _vm.exitDialog = $$v;
      },
      expression: "exitDialog"
    }
  }) : _vm._e(), _c('title-row', {
    attrs: {
      "title": "Benntlévők listája",
      "subtitle": `${_vm.dataTable.itemsLength > 0 ? _vm.dataTable.itemsLength : 0} személy tartózkodik bent`
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('title-row-button', {
          attrs: {
            "color": _vm.polling ? 'primary' : 'muted'
          },
          on: {
            "click": _vm.togglePolling
          }
        }, [!_vm.loading ? _c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(_vm._s(_vm.polling ? 'mdi-stop' : 'mdi-play'))]) : _c('v-progress-circular', {
          staticClass: "mr-2 ml-n1",
          attrs: {
            "indeterminate": "",
            "size": "18",
            "width": "2"
          }
        }), _vm._v(" Frissítés ")], 1), _c('title-row-button', {
          attrs: {
            "color": "muted"
          },
          on: {
            "click": function ($event) {
              _vm.exportDialog = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-file-export")]), _vm._v(" Exportálás ")], 1)];
      },
      proxy: true
    }])
  }), _c('v-expansion-panels', {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e && e !== '' && e.length;
      })
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c('v-expansion-panel-content', [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-masked-text-field', {
    staticClass: "my-4 my-sm-0",
    attrs: {
      "label": "Név",
      "mask": _vm.$config.inputMasks.name,
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "name", $$v);
      },
      expression: "search.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-masked-text-field', {
    staticClass: "my-4 my-sm-0",
    attrs: {
      "label": "Kártyaszám",
      "mask": _vm.$config.inputMasks.cardNumber,
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.card_number,
      callback: function ($$v) {
        _vm.$set(_vm.search, "card_number", $$v);
      },
      expression: "search.card_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_vm.hasRight('DEVICE_READ') ? _c('v-autocomplete', {
    staticClass: "my-4 my-sm-0",
    attrs: {
      "items": _vm.zones,
      "placeholder": "Összes",
      "label": "Zóna",
      "dense": "",
      "clearable": "",
      "multiple": "",
      "hide-details": "",
      "item-value": "element_id",
      "item-text": "name"
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.zone_ids,
      callback: function ($$v) {
        _vm.$set(_vm.search, "zone_ids", $$v);
      },
      expression: "search.zone_ids"
    }
  }) : _c('v-masked-text-field', {
    staticClass: "my-4 my-sm-0",
    attrs: {
      "label": "Zóna",
      "mask": _vm.$config.inputMasks.name,
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.zone_name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "zone_name", $$v);
      },
      expression: "search.zone_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_vm.hasRight('DEVICE_READ') ? _c('v-autocomplete', {
    staticClass: "my-4 my-sm-0",
    attrs: {
      "items": _vm.gates,
      "placeholder": "Összes",
      "label": "Kapu",
      "mask": _vm.$config.inputMasks.name,
      "dense": "",
      "clearable": "",
      "multiple": "",
      "hide-details": "",
      "item-value": "element_id",
      "item-text": "name"
    },
    on: {
      "input": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.gate_ids,
      callback: function ($$v) {
        _vm.$set(_vm.search, "gate_ids", $$v);
      },
      expression: "search.gate_ids"
    }
  }) : _c('v-masked-text-field', {
    staticClass: "my-4 my-sm-0",
    attrs: {
      "label": "Kapu",
      "mask": _vm.$config.inputMasks.name,
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.gate_name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "gate_name", $$v);
      },
      expression: "search.gate_name"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-card', [_c('v-data-table', {
    attrs: {
      "headers": _vm.dataTableHeaders,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "loading": _vm.dataTable.loading
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.entry_datetime`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(" " + _vm._s(_vm._f("moment")(item.entry_datetime, 'from')) + " ")])];
            }
          }], null, true)
        }, [_vm._v(" " + _vm._s(_vm._f("moment")(item.entry_datetime, 'lll')) + " ")])];
      }
    }, _vm._l(_vm.extraFields, function (field) {
      return {
        key: `item.extra_fields.${field.name}`,
        fn: function (_ref3) {
          var item = _ref3.item,
            index = _ref3.index;
          return [item.extra_fields && item.extra_fields[field.name] ? _c('dynamic-field-value', {
            key: `dynamic-field-value-${index}-${field.name}`,
            attrs: {
              "value": item.extra_fields[field.name],
              "field": field
            }
          }) : _vm._e()];
        }
      };
    }), {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          staticClass: "rounded-sm",
          attrs: {
            "small": "",
            "text": "",
            "fab": "",
            "dark": "",
            "depressed": "",
            "color": "primary",
            "title": "Kiléptetés"
          },
          on: {
            "click": function ($event) {
              _vm.exitDialog = item;
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-exit-run")])], 1)];
      }
    }], null, true)
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }