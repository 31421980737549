var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.open,
      "persistent": "",
      "max-width": "600"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-exit-run")]), _vm._v(" Kiléptetés "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Név")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.form.name))])]), _c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Zóna")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.value.zone_name || '-'))])])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Kilépés helye",
      "items": _vm.gates,
      "filled": "",
      "item-text": "name",
      "item-value": "element_id",
      "hide-details": !_vm.errors.exit_gate_id,
      "error-messages": _vm.errors.exit_gate_id,
      "dense": ""
    },
    model: {
      value: _vm.form.exit_gate_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "exit_gate_id", $$v);
      },
      expression: "form.exit_gate_id"
    }
  })], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": "",
      "block": "",
      "x-large": "",
      "loading": _vm.loading,
      "disabled": !_vm.form.exit_gate_id
    },
    on: {
      "click": _vm.exit
    }
  }, [_vm._v("Kiléptetés")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }